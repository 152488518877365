import React from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../Custom/CustomInput'
import CustomLabel from '../../../Custom/CustomLabel'

const Nets = ({data, setData}) => {

    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setData(prev => prev.map(item => {
          if (item.id === data.id) return {
            ...item,
            [field]: value
          }
          return item
        }))
    }
    
      const handleCheckChange = (e) => {
        const field = e.target.name;
        const value = e.target.checked;
        setData(prev => prev.map(item => {
          if (item.id === data.id) return {
            ...item,
            [field]: value
          }
          return item
        }))
      }

  return (
    <React.Fragment>
        <div className="hstack gap-3">

            <div className="form-check form-switch">
                <input name="status" checked={data.status} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-nets" />
                <CustomLabel className="form-check-label" htmlFor="active-nets">Active</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="status_test_mode" checked={data.status_test_mode} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-nets-test-mode" />
                <CustomLabel className="form-check-label" htmlFor="active-nets-test-mode">Test Mode</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="guest" checked={data.guest} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="nets-guest" />
                <CustomLabel className="form-check-label" htmlFor="nets-guest">Guest</CustomLabel>
            </div>

        </div>

        <Row className='mt-1 gy-3'>

            <Col lg={6}>
                <CustomLabel>Live Secret</CustomLabel>
                <CustomInput 
                    type="text"
                    name="live_secret"
                    value={data.live_secret}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Live Checkout</CustomLabel>
                <CustomInput 
                    type="text"
                    name="live_checkout"
                    value={data.live_checkout}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Test Secret</CustomLabel>
                <CustomInput 
                    type="text"
                    name="test_secret"
                    value={data.test_secret}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Test Checkout</CustomLabel>
                <CustomInput 
                    type="text"
                    name="test_checkout"
                    value={data.test_checkout}
                    onChange={handleChange}
                />
            </Col>

        </Row>

    </React.Fragment>

  )
}

export default Nets