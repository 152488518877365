import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import ToggleSwitch from "../../../../../Common/ToggleSwitch";
import CustomLabel from "../../../../../Custom/CustomLabel";
import CustomInput from "../../../../../Custom/CustomInput";
import { useTranslation } from "react-i18next";
import ChangeAllInputs from "./ChangeAllInputs";
import AddComponentItem from "./AddComponentItem";
import BootstrapTable from "../../../../../Common/BootstrapTable";

const FormComponentsTable = ({
  data,
  setData,
  componentId,
  maxComponents,
  singleSelect,
  selectedComponent,
  watch,
  addComponents
}) => {
  
  const { t } = useTranslation();
  
  const handleSelectAll = () => {
    setData(prev => prev.map(component => {
      if (component.component_id === componentId) return {
          ...component,
          items: component.items.map(item => ({
              ...item,
              selected: !component.items.every(item => item.selected)
          }))
      }
      return component
    }))
};

  const handleCheckChange = (rowId, basic) => {
    setData((prev) =>
      prev.map((component) => {
        if (component.component_id === componentId)
          return {
            ...component,
            items: component.items.map((item) => {
              if (item.id === rowId) {
                return {
                  ...item,
                  basic,
                  ...(basic === 1 || basic === 2
                    ? {
                        price: null,
                      }
                    : {}),
                };
              }
              return item;
            }),
          };
        return component;
      })
    );
  };

  const handleInputChange = (rowId, field, value) => {
    setData((prev) =>
      prev.map((component) => {
        if (component.component_id === componentId)
          return {
            ...component,
            items: component.items.map((item) => {
              if (item.id === rowId)
                return {
                  ...item,
                  [field]: value,
                };
              return item;
            }),
          };
        return component;
      })
    );
  };

  const handleChangeAllInputs = (field, value) => {
    setData((prev) =>
      prev.map((component) => {
        if (component.component_id === componentId)
          return {
            ...component,
            items: component.items.map((item) => {
              if (item.selected) return {
                ...item,
                [field]: value,
              }
              return item
            }),
          };
        return component;
      })
    );
  }

  const handleChangeAllPriceInputs = (field, value) => {
    setData((prev) =>
      prev.map((component) => {
        if (component.component_id === componentId)
          return {
            ...component,
            items: component.items.map((item) => {
              if (item.selected && ( +singleSelect === 2 ? true : item.basic === 0)) return {
                ...item,
                [field]: value,
              }
              return item
            }),
          };
        return component;
      })
    );
  }

  const handleComponentChange = (field, value) => {
    setData((prev) =>
      prev.map((component) => {
        if (component.component_id === componentId)
          return {
            ...component,
            [field]: value,
            ...(field === "single_select" && value !== 2 ? {
                  items: component.items.map((item) => ({
                    ...item,
                    price: isStatusSelected(item.basic) || isIncluded(item.basic) ? null : item.price
                  })),
                }
              :  {}
              ),
          };
        return component;
      })
    );
  };

  const isStatusSelected = (basic) => {
    return basic === 1;
  };

  const isIncluded = (basic) => {
    return basic === 2;
  };

  const isMaxComp = watch && watch("max_active");


  const columns = [

      {
        name: t('Icon'),
        selector: row => (
          <img 
            src={row.image} 
            at={row.name} 
            style={{
              width: '70px',
            }}
          />
        )       
        ,
      },

      {
        name: t('Name'),
        selector: row => (
          <label
            className="cursor-pointer user-select-none"
            htmlFor={`item-${row.id}`}
          >
            {row.name}
          </label>
        )       
        ,
      },


      ...(+singleSelect !== 2 ? [{
        name: 'Options',
        selector: row => (
          <ToggleSwitch
            values={[
              {
                label: "optional",
                value: 0
              },

              {
                label: "selected",
                value: 1
              },

              {
                label: "included",
                value: 2
              },
          ]}
            selected={row.basic}
            selectedRow={row.selected}
            rowId={row.id}
            onChange={handleCheckChange}
          />
        ),
        style: {
          width: '20%'
        }
      }] : []),

      {
        name: (
          <ChangeAllInputs 
            label="Price"
            field="price"
            onChange={handleChangeAllPriceInputs}
          />
        ),
        selector: row => (
            <input
              className="form-control"
              onChange={(e) =>
                handleInputChange(row.id, "price", e.target.value)
              }
              value={row.price ?? ""}
              disabled={!row.selected || (+singleSelect !== 2 && (isIncluded(row.basic) || isStatusSelected(row.basic)))
              }
            />
        )
      },

      {
        name: (
          <ChangeAllInputs 
            label={+singleSelect !== 2 ? "Extra Price" : "Default Quantity"}
            field={+singleSelect !== 2 ? "price_extra" : "default_qty"}
            onChange={handleChangeAllInputs}
          />
        ),
        selector: row => +singleSelect !== 2 ? (
          <input
            className="form-control"
            onChange={(e) =>
              handleInputChange(
                row.id,
                "price_extra",
                e.target.value
              )
            }
            value={row.price_extra ?? ""}
            disabled={!row.selected}
          />
        ) : (
          <input
            className="form-control"
            onChange={(e) =>
              handleInputChange(
                row.id,
                "default_qty",
                e.target.value
              )
            }
            value={row.default_qty ?? ""}
            disabled={!row.selected}
          />
        )
      },

      ...(+singleSelect === 2 ? [{
        name: (
          <ChangeAllInputs 
              label={"Max Quantity"}
              field={"max_qty"}
              onChange={handleChangeAllInputs}
          />
        ),
        selector: row =>  (
          <input
              className="form-control"
              onChange={(e) =>
                handleInputChange(
                  row.id,
                  "max_qty",
                  e.target.value
                )
              }
              value={row.max_qty ?? ""}
              disabled={!row.selected}
          />
        )          
      }] : [])
  ]

  useEffect(() => {
    if (!isMaxComp) {
      handleComponentChange("max_compo", null);
    }
  }, [isMaxComp]);

  if (selectedComponent !== componentId) return null

  return (
      <Row className="gy-1">
          <Col lg={6} className="mt-3">
            <CustomLabel>Select Type</CustomLabel>
            <select
              className="form-select"
              value={singleSelect}
              onChange={(e) =>
                handleComponentChange("single_select", e.target.value)
              }
            >
              <option value="0">Checkbox</option>
              <option value="1">Radio</option>
              <option value="2">Counter</option>
            </select>
          </Col>

          {!!isMaxComp && (
            <Col lg={6} className="mt-3">
              <CustomLabel>Max Components</CustomLabel>
              <CustomInput
                type="number"
                value={maxComponents || ""}
                onChange={(e) =>
                  handleComponentChange("max_compo", e.target.value)
                }
              />
            </Col>
          )}
            <Col lg={12} className="mt-3">
              <BootstrapTable 
                data={data}
                columns={columns}
                enableSelection
                onSelectChange={handleInputChange}
                onSelectAllChange={handleSelectAll}
                extraRows={
                  addComponents &&
                  [
                    <tr>
                      <td className="d-flex justify-content-center align-items-center">
                        <AddComponentItem
                          id={selectedComponent}
                          setData={setData}
                        />
                      </td>
                    </tr>
                  ]
                }
              />
            </Col>
      </Row>
  );
};

export default FormComponentsTable;