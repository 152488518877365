import { useEffect, useState } from 'react'
import React from 'react'
import { Col, Row } from 'reactstrap'
import SelectFile from '../../../Common/SelectFile'
import CustomInput from '../../../Custom/CustomInput'
import CustomLabel from '../../../Custom/CustomLabel'
import { toBase64 } from '../../../../helpers/toBase64'
import StripeWebhook from './StripeWebhook'

const Stripe = ({data, setData}) => {

    const [image, setImage] = useState({
        url: data?.logo,
        file: null
    })

    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setData(prev => prev.map(item => {
          if (item.id === data.id) return {
            ...item,
            [field]: value
          }
          return item
        }))
    }
    
    const handleCheckChange = (e) => {
        const field = e.target.name;
        const value = e.target.checked;
        setData(prev => prev.map(item => {
            if (item.id === data.id) return {
            ...item,
            [field]: value
            }
            return item
        }))
    }

    const handleFileChange = async () => {
      const url = await toBase64(image.file);
      setData(prev => prev.map(item => {
          if (item.id === data.id) return {
          ...item,
          logo: url
          }
          return item
      }))
    }   

      useEffect(() => {
          if (image.file) {
              handleFileChange();
          }
      }, [image])

  return (
    <React.Fragment>

        <div className="hstack gap-3">
            <div className="form-check form-switch">
                <input name="status" checked={data.status} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-stripe" />
                <CustomLabel className="form-check-label" htmlFor="active-stripe">Active</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="status_test_mode" checked={data.status_test_mode} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-stripe-test-mode" />
                <CustomLabel className="form-check-label" htmlFor="active-stripe-test-mode">Test Mode</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="guest" checked={data.guest} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="stripe-guest" />
                <CustomLabel className="form-check-label" htmlFor="stripe-guest">Guest</CustomLabel>
            </div>
        </div>

            <div>
              
                <CustomLabel className="fs-20 my-3 border-bottom">Stripe</CustomLabel>
                <Row className='gy-2'>
                    <Col lg={6}>
                      <CustomLabel>Publishable Key</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="pubishable_key"
                          value={data.pubishable_key}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <CustomLabel>Secret Key</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="secret_key"
                          value={data.secret_key}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <CustomLabel>Web Hook Secret</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="webhook_secret"
                          value={data.webhook_secret}
                          onChange={handleChange}
                      />
                    </Col>

                </Row>

            </div>

            <div>
                <CustomLabel className="fs-20 my-3 border-bottom">Test Mode</CustomLabel>
                <Row>
                    <Col lg={6}>
                      <CustomLabel>Publishable Key</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="pubishable_key_test"
                          value={data.pubishable_key_test}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <CustomLabel>Secret Key</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="secret_key_test"
                          value={data.secret_key_test}
                          onChange={handleChange}
                      />
                  </Col>
                </Row>
            </div>

            
            <div>
                <CustomLabel className="fs-20 my-3 border-bottom">Webhook</CustomLabel>
                <StripeWebhook />
            </div>

    </React.Fragment>

  )
}

export default Stripe