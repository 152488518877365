import html2pdf from "html2pdf-jspdf2";
import moment from 'moment';

const style = /*html*/`

  <style>
    .w-100 {
      width: 100%;
    }
    .w-50 {
      width: 50%;
    }
    .card-header {
      background-color: #eee !important;
    }

    .card-title {
      background-color: #eee !important;
      font-size: 15px;
      padding: 10px 20px;
      letter-spacing: 1px;
      margin-bottom: 0px;
    }

    .card-body {
      background-color: white;
    }

    .card-text {
      font-size: .9em;
      color: #666;
      line-height: 1.2em;
      margin-bottom: 4px !important;
    }

    .card {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0.5rem;
      font-family: inherit;
      font-weight: 500;
      line-height: 1.2;
      color: inherit;
    }
    hr {
      margin-top: 0.5rem;
      margin-bottom: 0.8rem;
      border: 0;
      border-top: 3px dashed rgba(0, 0, 0, 0.1);
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
    }

    td {
      font-size: .8rem;
    }

    .tabletitle, .tablefooter {
      padding: 5px;
      background: #eee;
    }

    .tabletitle td {
      padding-block: .3rem;
      font-size: 14px;
    }

    .tablefooter td {
      padding-block: .2rem;
      font-size: 12px;
    }

    .service {
      border: 1px solid #eee;
    }
    /*
      .item {
          width: 50%;
      }

    */
    .itemtext {
      font-size: 0.9em;
    }

    #table h2 {
      font-size: 0.9em;
    }

    .table_page {
      width: 840px;
      margin: auto;
    }
    .font-bold {
      font-weight: 700;
    }
    .w-50 {
      width: 50%;
    }
    .w-100 {
      width: 100%;
    }
    .float-left {
      float: left;
    }
    .float-right {
      float: right;
    }
    .rightdir {
      text-align: right;
      display: inline-block;
    }
    .leftdir {
      text-align: left;
      display: inline-block;
    }
    .text-right {
      text-align: right;
    }
    .tabletitle h2 {
      font-size: 14px;
      padding-block: .2rem;
    }

    /*******/
    #customers {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 35%;
      margin-bottom: 40px;
      margin-top: 50px;
    }

    #customers td,
    #customers th {
      padding: 8px;
    }
    #customers .head {
      border-bottom: 2px solid black;
    }

    #customers th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      color: black;
    }

    .pdf-logo {
      width: 150px;
    }

    .pdf-header {
      width: 100%;
      padding-top: 1rem;
      margin-bottom: 3rem;
    }

    .right-header {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .company, .right-section-details {
      display: flex;
      flex-direction: column;
    }

    .company span {
      font-size: 12px;
    }

    .right-section-details span {
      font-size: 12px;
    }

    .right-section-details .order-number {
      font-weight: bolder;
      font-size: 14px;
    }

    table {
      page-break-inside:auto;
    }

    table tr {
      page-break-inside:avoid; 
      page-break-after:auto;
    }

    table td {
      max-width: 200px;
    }
  </style>

`


const calculateTotals = (data, zones) => {

  const taxes = [...new Set([...data.map(item => +item.taxe.replace('%', '')), ...zones.map(item => +item.tax_rate)])].sort((a, b) => a - b).map(item => `${item}%`);

  const totals = Object.fromEntries(taxes.map(tax => [tax, 
    (() => {
      const arr = [...data.filter(item => item.taxe === tax), ...zones.filter(item => `${item.tax_rate}%` === tax)];
      return {

        totalNetPrice: Number(arr.reduce((accum, val) => {
          return accum + val.price_no_tax           
        }, 0)).toFixed(2),

        totalTax: Number(arr.reduce((accum, val) => {
          return accum + val.tax_amount           
        }, 0)).toFixed(2),

        totalPrice: Number(arr.reduce((accum, val) => {
          return accum + val.price_tax           
        }, 0)).toFixed(2)
      }
    })()
  ]))
  return totals
}

export const zReportPdf = ({data, period, settings}) => {

  const totals = calculateTotals(data.datas?.[period], data.delivery);

  const currency = settings.paymentSettings?.settings?.currency || 'Nok';

  const htmlData = /*html*/`<!DOCTYPE html>
  <html lang="en">

    <head>

        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>

        ${style}

      </head>

      <body class="table_page">
            <div class="pdf-header">
                    <div>
                            <h4 class="font-bold">Electronic Journal ${moment().format('DD-MM-YYYY HH:mm:ss')}</h4>
                            <h5 class="font-bold">Z-rapport: ${data.z_reporter || ''}</h5>
                            <h6 class="card-text">Company: ${data.company_name || ''}</h6>
                            <p class="card-text ">Org. Nr: ${data.org_nr || ''}</p>
                            <p class="card-text">Address: ${data.address || ''}</p>
                            <p class="card-text">Sale Between: ${data.date || ''}</p>
                          </div>
                    </div>
              </div>
        <p
          style="font-weight: bolder"
        >
        Services and products
        </p>

        <table style="margin-top: 0px">
            <tr class="tabletitle">
              <td>
                <h2>Service.</h2>
              </td>
              <td>
                  <h2>Quantity</h2>
              </td>
              <td>
                  <h2>Tax Rate</h2>
              </td>
              <td>
                  <h2>Pre-tax</h2>
              </td>
              <td>
                  <h2>Tax Amount</h2>
              </td>
              <td>
                  <h2>Total</h2>
              </td>
            </tr>
            ${data.datas?.[period].map((item) => {
              return /*html*/`
                <tr class="service">
                  <td class="tableitem">
                    ${item.food_name || '-'}
                  </td>

                  <td class="tableitem">
                    ${item.qty ?? '-'}
                  </td>

                  <td class="tableitem">
                    ${item.taxe}
                  </td>

                  <td class="tableitem">
                    ${Number(item.price_no_tax || 0).toFixed(2)} ${currency}
                  </td>

                  <td class="tableitem">
                    ${Number(item.tax_amount || 0).toFixed(2)} ${currency}
                  </td>

                  <td class="tableitem">
                    ${Number(item.price_tax || 0).toFixed(2)} ${currency}
                  </td>
                </tr>`
              }).join('')}
              
              <tr class="tablefooter">
                <td class="tableitem">
                  Total
                </td>

                <td class="tableitem">
                </td>

                <td class="tableitem">
                </td>

                <td class="tableitem">
                  ${Number(data.datas?.[period].reduce((accum, val) => accum + val.price_no_tax, 0)).toFixed(2)} ${currency}
                </td>

                <td class="tableitem">
                  ${Number(data.datas?.[period].reduce((accum, val) => accum + val.tax_amount, 0)).toFixed(2)} ${currency}
                </td>

                <td class="tableitem">
                  ${Number(data.datas?.[period].reduce((accum, val) => accum + val.price_tax, 0)).toFixed(2)} ${currency}
                </td>
              </tr>

          </table>

          <table>
            <tr class="tabletitle">

                <td class="item">
                    <h2>Zone</h2>
                </td>

                <td class="item">
                    <h2>Delivered</h2>
                </td>

                <td class="item">
                    <h2>Pre-tax</h2>
                </td>

                <td class="item">
                    <h2>Tax Rate</h2>
                </td>

                <td class="item">
                    <h2>Tax Amount</h2>
                </td>

                <td class="item">
                    <h2>Final</h2>
                </td>
            </tr>

            ${data.delivery?.map(zone => {
              return /*html*/`
                <tr class="service">

                    <td class="tableitem">
                        ${zone.zone ?? '-'}
                    </td>

                    <td class="tableitem">
                        ${zone.delivery_number || 0}
                    </td>

                    <td class="tableitem">
                        ${Number(zone.price_no_tax || 0).toFixed(2)} ${currency}
                    </td>

                    <td class="tableitem">
                        ${zone.tax_rate ?? 0}%
                    </td>

                    <td class="tableitem">
                        ${Number(zone.tax_amount || 0).toFixed(2)} ${currency}
                    </td>

                    <td class="tableitem"> 
                        ${Number(zone.price_tax || 0).toFixed(2)} ${currency}
                    </td>
                </tr>
              `
            }).join('')}

                <tr class="tablefooter">

                  <td class="tableitem">
                    Total
                  </td>

                  <td class="tableitem">
                    ${Number(data.delivery.reduce((accum, val) => accum + val.delivery_number, 0))}
                  </td>

                  <td class="tableitem">
                    ${Number(data.delivery.reduce((accum, val) => accum + val.price_no_tax, 0)).toFixed(2)} ${currency}
                  </td>

                  <td class="tableitem">
                  </td>

                  <td class="tableitem">
                    ${Number(data.delivery.reduce((accum, val) => accum + val.tax_amount, 0)).toFixed(2)} ${currency}
                  </td>

                  <td class="tableitem">
                    ${Number(data.delivery.reduce((accum, val) => accum + val.price_tax, 0)).toFixed(2)} ${currency}
                  </td>
                </tr>
        </table>

        <table>

            <tr class="tabletitle">

              <td class="Rate">
                Tax Rate
              </td>

              <td class="item">
                Services Pre-tax
              </td>

              <td class="Hours">
                Services Tax Amount
              </td>
          
              <td class="Rate">
                Total
              </td>

            </tr>

            ${Object.keys(totals).map(key => 
              /*html*/`<tr class="service">
                <td class="tableitem">
                    ${key}
                </td>

                <td class="tableitem">
                    ${totals[key].totalNetPrice} ${currency}
                </td>

                <td class="tableitem">
                    ${totals[key].totalTax} ${currency}
                </td>

                <td class="tableitem">
                    ${totals[key].totalPrice} ${currency}
                </td>
              </tr>`
            ).join('')}

      </table>


      <table>
            <tr class="tabletitle">

                <td class="item">
                    <h2>Method</h2>
                </td>

                <td class="item">
                    <h2>Total</h2>
                </td>

                <td class="item">
                    <h2>Refund</h2>
                </td>

                <td class="item">
                    <h2>Fees</h2>
                </td>

                <td class="item">
                    <h2>Final</h2>
                </td>
            </tr>

            ${data.payments?.map(payment => {
              return /*html*/`
                <tr class="service">
                    <td class="tableitem">
                        ${payment.name || '-'}
                    </td>

                    <td class="tableitem">
                        ${Number(payment.total || 0).toFixed(2)}  ${currency}
                    </td>

                    <td class="tableitem">
                        ${Number(payment.refund || 0).toFixed(2)} ${currency}
                    </td>

                    <td class="tableitem">
                        ${Number(payment.fees || 0).toFixed(2)} ${currency}
                    </td>

                    <td class="tableitem">
                        ${Number(payment.final || 0).toFixed(2)} ${currency}
                    </td>
                </tr>
              `
            }).join('')}

            <tr class="tablefooter">

              <td class="tableitem">
                Total
              </td>

              <td class="tableitem">
                ${Number(data.payments.reduce((accum, val) => accum + val.total, 0)).toFixed(2)} ${currency}
              </td>

              <td class="tableitem">
                ${Number(data.payments.reduce((accum, val) => accum + val.refund, 0)).toFixed(2)} ${currency}
              </td>

              <td class="tableitem">
                ${Number(data.payments.reduce((accum, val) => accum + val.fees, 0)).toFixed(2)} ${currency}
              </td>

              <td class="tableitem">
                ${Number(data.payments.reduce((accum, val) => accum + val.final, 0)).toFixed(2)} ${currency}
              </td>
            </tr>

        </table>

      </body>
  </html>

  `;

  const opt = {
    margin: [0, 5],
    filename: 'myfile.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      dpi: 192,
      scale:4,
      letterRendering: true,
      useCORS: true
    },
  };

  html2pdf().set(opt).from(htmlData).toPdf().get('pdf').then(function (pdf) {
    let totalPages = pdf.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.setTextColor(150);
    }
    window.open(pdf.output('bloburl', '_blank'));
  });
};

export const reportSummaryPdf = ({data, period, settings}) => {

  const totals = calculateTotals(data.datas?.[period], data.delivery);

  const currency = settings.paymentSettings?.settings?.currency || 'Nok';

  const htmlData = /*html*/`<!DOCTYPE html>
  <html lang="en">

    <head>
    
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>

        ${style}

      </head>

      <body class="table_page">
            <div class="pdf-header">
                    <div>
                            <h4 class="font-bold">Sales report (Category)</h4>
                            <h6 class="card-text">Company: ${data.company_name || ''}</h6>
                            <p class="card-text ">Org. Nr: ${data.org_nr || ''}</p>
                            <p class="card-text">Address: ${data.address || ''}</p>
                            <p class="card-text">Sale between: ${data.date || ''}</p>
                            <p class="card-text">Downloaded: ${moment().format('DD-MM-YYYY HH:mm:ss')}</p>
                          </div>
                    </div>
              </div>
        <p
          style="font-weight: bolder"
        >
          Services and products
        </p>

        <table style="margin-top: 0px">
            <tr class="tabletitle">
              <td>
                <h2>Service.</h2>
              </td>
              <td>
                  <h2>Quantity</h2>
              </td>
              <td>
                  <h2>Tax Rate</h2>
              </td>
              <td>
                  <h2>Pre-tax</h2>
              </td>
              <td>
                  <h2>Tax Amount</h2>
              </td>
              <td>
                  <h2>Total</h2>
              </td>
            </tr>
            ${data.datas?.[period].map((item) => {
              return /*html*/`
                <tr class="service">
                  <td class="tableitem">
                    ${item.food_name || '-'}
                  </td>

                  <td class="tableitem">
                    ${item.qty ?? '-'}
                  </td>

                  <td class="tableitem">
                    ${item.taxe}
                  </td>

                  <td class="tableitem">
                    ${Number(item.price_no_tax || 0).toFixed(2)} ${currency}
                  </td>

                  <td class="tableitem">
                    ${Number(item.tax_amount || 0).toFixed(2)} ${currency}
                  </td>

                  <td class="tableitem">
                    ${Number(item.price_tax || 0).toFixed(2)} ${currency}
                  </td>
                </tr>`
              }).join('')}
              
              <tr class="tablefooter">
                <td class="tableitem">
                  Total
                </td>

                <td class="tableitem">
                </td>

                <td class="tableitem">
                </td>

                <td class="tableitem">
                  ${Number(data.datas?.[period].reduce((accum, val) => accum + val.price_no_tax, 0)).toFixed(2)} ${currency}
                </td>

                <td class="tableitem">
                  ${Number(data.datas?.[period].reduce((accum, val) => accum + val.tax_amount, 0)).toFixed(2)} ${currency}
                </td>

                <td class="tableitem">
                  ${Number(data.datas?.[period].reduce((accum, val) => accum + val.price_tax, 0)).toFixed(2)} ${currency}
                </td>
              </tr>

          </table>

          <table>
            <tr class="tabletitle">

                <td class="item">
                    <h2>Zone</h2>
                </td>

                <td class="item">
                    <h2>Delivered</h2>
                </td>

                <td class="item">
                    <h2>Pre-tax</h2>
                </td>

                <td class="item">
                    <h2>Tax Rate</h2>
                </td>

                <td class="item">
                    <h2>Tax Amount</h2>
                </td>

                <td class="item">
                    <h2>Final</h2>
                </td>
            </tr>

            ${data.delivery?.map(zone => {
              return /*html*/`
                <tr class="service">

                    <td class="tableitem">
                        ${zone.zone ?? '-'}
                    </td>

                    <td class="tableitem">
                        ${zone.delivery_number || 0}
                    </td>

                    <td class="tableitem">
                        ${Number(zone.price_no_tax || 0).toFixed(2)} ${currency}
                    </td>

                    <td class="tableitem">
                        ${zone.tax_rate ?? 0}%
                    </td>

                    <td class="tableitem">
                        ${Number(zone.tax_amount || 0).toFixed(2)} ${currency}
                    </td>

                    <td class="tableitem"> 
                        ${Number(zone.price_tax || 0).toFixed(2)} ${currency}
                    </td>
                </tr>
              `
            }).join('')}

                <tr class="tablefooter">

                  <td class="tableitem">
                    Total
                  </td>

                  <td class="tableitem">
                    ${Number(data.delivery.reduce((accum, val) => accum + val.delivery_number, 0))}
                  </td>

                  <td class="tableitem">
                    ${Number(data.delivery.reduce((accum, val) => accum + val.price_no_tax, 0)).toFixed(2)} ${currency}
                  </td>

                  <td class="tableitem">
                  </td>

                  <td class="tableitem">
                    ${Number(data.delivery.reduce((accum, val) => accum + val.tax_amount, 0)).toFixed(2)} ${currency}
                  </td>

                  <td class="tableitem">
                    ${Number(data.delivery.reduce((accum, val) => accum + val.price_tax, 0)).toFixed(2)} ${currency}
                  </td>
                </tr>
        </table>

        <table>

            <tr class="tabletitle">

              <td class="Rate">
                Tax Rate
              </td>

              <td class="item">
                Services Pre-tax
              </td>

              <td class="Hours">
                Services Tax Amount
              </td>
          
              <td class="Rate">
                Total
              </td>

            </tr>

            ${Object.keys(totals).map(key => 
              /*html*/`<tr class="service">
                <td class="tableitem">
                    ${key}
                </td>

                <td class="tableitem">
                    ${totals[key].totalNetPrice} ${currency}
                </td>

                <td class="tableitem">
                    ${totals[key].totalTax} ${currency}
                </td>

                <td class="tableitem">
                    ${totals[key].totalPrice} ${currency}
                </td>
              </tr>`
            ).join('')}

        </table>


        <table>
            <tr class="tabletitle">

                <td class="item">
                    <h2>Method</h2>
                </td>

                <td class="item">
                    <h2>Total</h2>
                </td>

                <td class="item">
                    <h2>Refund</h2>
                </td>

                <td class="item">
                    <h2>Fees</h2>
                </td>

                <td class="item">
                    <h2>Final</h2>
                </td>
            </tr>

            ${data.payments?.map(payment => {
              return /*html*/`
                <tr class="service">

                    <td class="tableitem">
                        ${payment.name || '-'}
                    </td>

                    <td class="tableitem">
                        ${Number(payment.total || 0).toFixed(2)}  ${currency}
                    </td>

                    <td class="tableitem">
                        ${Number(payment.refund || 0).toFixed(2)} ${currency}
                    </td>

                    <td class="tableitem">
                        ${Number(payment.fees || 0).toFixed(2)} ${currency}
                    </td>

                    <td class="tableitem">
                        ${Number(payment.final || 0).toFixed(2)} ${currency}
                    </td>
                
                </tr>
              `
            }).join('')}

            <tr class="tablefooter">

              <td class="tableitem">
                Total
              </td>

              <td class="tableitem">
                ${Number(data.payments.reduce((accum, val) => accum + val.total, 0)).toFixed(2)} ${currency}
              </td>

              <td class="tableitem">
                ${Number(data.payments.reduce((accum, val) => accum + val.refund, 0)).toFixed(2)} ${currency}
              </td>

              <td class="tableitem">
                ${Number(data.payments.reduce((accum, val) => accum + val.fees, 0)).toFixed(2)} ${currency}
              </td>

              <td class="tableitem">
                ${Number(data.payments.reduce((accum, val) => accum + val.final, 0)).toFixed(2)} ${currency}
              </td>
            </tr>

        </table>


      </body>
  </html>

  `;

  const opt = {
    margin: [0, 5],
    filename: 'myfile.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      dpi: 192,
      scale:4,
      letterRendering: true,
      useCORS: true
    },
  };

  html2pdf().set(opt).from(htmlData).toPdf().get('pdf').then(function (pdf) {
    let totalPages = pdf.internal.getNumberOfPages();

    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.setTextColor(150);
    }
    window.open(pdf.output('bloburl', '_blank'));
  });
};


export const printPdf = (htmlData) => {

  const opt = {
    margin: [0, 5],
    filename: 'myfile.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      dpi: 192,
      scale:4,
      letterRendering: true,
      useCORS: true
    },
  };

  html2pdf().set(opt).from(htmlData).toPdf().get('pdf').then(function (pdf) {
    let totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(10);
      pdf.setTextColor(150);
    }
    window.open(pdf.output('bloburl', '_blank'));
  });
};

