import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Spinner } from "reactstrap";

const CustomButton = ({ children, isLoading, ...rest }) => {

  const { t } = useTranslation();

  return (
    <Button disabled={isLoading} {...rest}>
      {isLoading ? <Spinner size="sm" /> : typeof children === 'string' ? t(children) : children}
    </Button>
  );
};

export default CustomButton;
