import React, { useEffect, useState } from "react";

export const getPathname = (path) => {
  const PREFIX = "panel";
  if (path) return `/${PREFIX}/${path}`;
  return `/${PREFIX}`;
};

const Navdata = () => {

  // ! Main links

  const [isHome, setIsHome] = useState(false);
  const [isOrders, setIsOrders] = useState(false);
  const [isFood, setIsFood] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isReports, setIsReports] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("tables-and-rooms");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "home") {
      setIsHome(false);
    }

    if (iscurrentState !== "food") {
      setIsFood(false);
    }

    if (iscurrentState !== "orders") {
      setIsOrders(false);
    }

    if (iscurrentState !== "users") {
      setIsUsers(false);
    }

    if (iscurrentState !== "settings") {
      setIsSettings(false);
    }
  }, [isHome, isOrders, isFood, isUsers, isSettings, iscurrentState]);

  const menuItems = [
    {
      id: "home",
      label: "Home",
      icon: "ri-home-8-line",
      link: getPathname(),
      stateVariables: isHome,
      click: function (e) {
        e.preventDefault();
        setIsHome(!isHome);
        setIscurrentState("home");
        updateIconSidebar(e);
      },
    },
    {
      id: "orders",
      label: "Orders",
      icon: "ri-shopping-cart-2-line",
      link: "/#",
      stateVariables: isOrders,
      click: function (e) {
        e.preventDefault();
        setIsOrders(!isOrders);
        setIscurrentState("orders");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "order-list",
          label: "Orders",
          link: getPathname("orders"),
          parentId: "orders",
        },
        {
          id: "oline-order-settings",
          label: "Online Order Settings",
          link: getPathname("orders/settings"),
          parentId: "orders",
        },
        {
          id: "delivery-settings",
          label: "Delivery Settings",
          link: getPathname("orders/delivery-settings"),
          parentId: "orders",
        },
        {
          id: "sms-settings",
          label: "SMS Settings",
          link: getPathname("orders/sms-settings"),
          parentId: "orders",
        },
        {
          id: "track-delivery-agents",
          label: "Track Delivery Agents",
          link: getPathname("orders/track-agents"),
          parentId: "orders",
        },
        {
          id: "rooms",
          label: "Rooms",
          link:  getPathname("orders/rooms"),
          parentId: "orders",
        },
        {
          id: "tables",
          label: "Tables",
          link:  getPathname("orders/tables"),
          parentId: "orders",
        },
        {
          id: "table-services",
          label: "Table Services",
          link:  getPathname("orders/table-services"),
          parentId: "orders",
        },
        {
          id: "coupons",
          label: "Coupons",
          link: getPathname("orders/coupons"),
          parentId: "orders",
        },
        {
          id: "shelves",
          label: "Shelves",
          link: getPathname("orders/shelves"),
          parentId: "orders",
        },
        {
          id: "black-white",
          label: "Black & White",
          link: getPathname("orders/black-white"),
          parentId: "orders",
        },
      ],
    },

    {
      id: "food",
      label: "Food",
      icon: "ri-cake-2-line",
      link: "/#",
      stateVariables: isFood,
      click: function (e) {
        e.preventDefault();
        setIsFood(!isFood);
        setIscurrentState("food");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "food-menu",
          label: "Food Menu",
          link: getPathname("food/menu"),
          parentId: "food",
        },
        {
          id: "categories",
          label: "Categories",
          link: getPathname("food/categories"),
        },
        {
          id: "components",
          label: "Components",
          link: getPathname("food/components"),
          parentId: "food",
        },
        {
          id: "offers",
          label: "Offers",
          link: getPathname("food/offers"),
          parentId: "food",
        },
        {
          id: "allergy",
          label: "Allergy",
          link: getPathname("food/allergy"),
          parentId: "food",
        },
        {
          id: "options",
          label: "Options",
          link: getPathname("food/options"),
          parentId: "food",
        },
        {
          id: "sizes",
          label: "Sizes",
          link: getPathname("food/sizes"),
          parentId: "food",
        },
        {
          id: "dough-bread",
          label: "Dough & Bread",
          link:  getPathname("food/dough-bread"),
          parentId: "food",
        },
        {
          id: "doneness",
          label: "Doneness",
          link: getPathname("food/doneness"),
          parentId: "food",
        },
        {
          id: "spice-levels",
          label: "Spice Levels",
          link: getPathname("food/spice-levels"),
          parentId: "food",
        },
        {
          id: "vat",
          label: "VAT",
          link: getPathname("food/vat"),
          parentId: "food",
        },

        {
          id: "printers",
          label: "Printers",
          link: getPathname("food/printers"),
          parentId: "food",
        },
      ],
    },

    {
      id: "users",
      label: "Users",
      icon: "ri-user-3-line",
      link: "/#",
      stateVariables: isUsers,
      click: function (e) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState("users");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "sub-users",
          label: "Users",
          link: getPathname("users"),
          parentId: "users",
        },
        {
          id: "groups",
          label: "Groups",
          link: getPathname("users/groups"),
          parentId: "users",
        },
      ],
    },

    {
      id: "settings",
      label: "Settings",
      icon: "ri-equalizer-line",
      link: "/#",
      stateVariables: isSettings,
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        setIscurrentState("settings");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "admins",
          label: "Admins",
          link: getPathname("admins"),
          parentId: "settings",
        },
        {
          id: "web-settings",
          label: "Web Settings",
          link: getPathname("settings"),
          parentId: "settings",
        },
        {
          id: "payment",
          label: "Payment",
          link: getPathname("settings/payment"),
          parentId: "settings",
        },
        {
          id: "social-logins",
          label: "Social Logins",
          link: getPathname("settings/social-logins"),
          parentId: "settings",
        },
        {
          id: "keys",
          label: "Keys",
          link: getPathname("settings/keys"),
          parentId: "settings",
        },
        {
          id: "branches",
          label: "Branches",
          link: getPathname("settings/branches"),
          parentId: "settings",
        },
        {
          id: "home-sliders",
          label: "Home Sliders",
          link: getPathname("settings/home-sliders"),
          parentId: "settings",
        },
        {
          id: "pages-menu",
          label: "Pages Menu",
          link: getPathname("settings/pages-menu"),
          parentId: "settings",
        },
        {
          id: "gallery",
          label: "Gallery",
          link: getPathname("settings/gallery"),
          parentId: "settings",
        },
        {
          id: "banners",
          label: "Banners",
          link: getPathname("settings/banners"),
          parentId: "settings",
        },
        {
          id: "language",
          label: "Language",
          link: getPathname("settings/language"),
          parentId: "settings",
        },
        {
          id: "menu",
          label: "Menu",
          link: getPathname("settings/menu"),
          parentId: "settings",
        },
        {
          id: "menu-builder",
          label: "Menu Builder",
          link: getPathname("settings/menu-builder"),
          parentId: "settings",
        },
        {
          id: "setup",
          label: "Setup",
          link: "/setup",
          parentId: "settings",
        },
      ],
    },

    {
      id: "reports",
      label: "Reports",
      icon: "ri-bar-chart-fill",
      link: "/#",
      stateVariables: isReports,
      click: function (e) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("reports");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "sub-reports",
          label: "Reports",
          link: getPathname("reports"),
          parentId: "reports",
        },
        {
          id: "reports-settings",
          label: "Settings",
          link: getPathname("reports/settings"),
          parentId: "reports",
        },
      ],
    },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
