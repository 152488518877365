import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import { getZones, setZones as setZonesApi } from '../../../../apis/Orders/deliverySettingsApis';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodDataAction } from '../../../../redux/actions/foodActions';
import CustomLabel from '../../../Custom/CustomLabel';
import CustomInput from '../../../Custom/CustomInput';
import MapContainer from './Map';
import CustomButton from '../../../Custom/CustomButton';
import useActions from '../../../../hooks/useActions';
import CustomCardTitle from '../../../Custom/CustomCardTitle';
import { errorMessage } from '../../../../helpers/errorMessage';
import CustomOption from '../../../Custom/CustomOption';
import { getCenterCoords } from '../../../../helpers/getCenterCoords';
import { isEmpty } from '../../../../helpers/objectFilter';
import { deliveryZonesRqBody } from '../../../../helpers/requests/Order/deliveryZonesRqBody';
import toastAlert from '../../../../helpers/toastAlert';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useTranslation } from 'react-i18next';
import { useBranches } from '../../../../hooks/useQueryHelpers';

const animatedComponents = makeAnimated();

const DeliveryZones = ({ shopLocation, mapsApiKey, branchId }) => {

    const [zones, setZones] = useState(null);

    const [errors, setErrors] = useState(null);

    const [centerCoords, setCenterCoords] = useState(null);

    const [data, setData] = useState(null);
    
    const [selectedZone, setSelectedZone] = useState(null);

    const { t } = useTranslation();

    useQuery(['zones'], () => getZones(branchId ? { branche_id : branchId } : {}), {
        onSuccess({data: {data}}) {
            setZones(data);
        }
    })

    const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

    const { branches } = useBranches();

    const { vat: { list: vat }} = useSelector((state) => state.food);

    const dispatch = useDispatch();

    const handleSetData = (zones) => {
        setData(zones.map(item => {
            return {
                id: item.id,
                name: item.name,
                price: item.price,
                price_tax: item.price_tax,
                tax_id: item.tax_id,
                branche_id: item.branche_id || null,
                stroke_color: item.stroke_color,
                fill_color: item.fill_color,
                min_order: item.min_order,
                points: item.points || [],
            }
        }));
    }


    const handleChange = (e) => {
        setData(prev => prev.map(item => {
            if (item.id === selectedZone) return {
                ...item,
                [e.target.name]: e.target.value
            }
            return item
        }))
    }

    const focusZone = (id, points) => {
        setSelectedZone(id)
        setCenterCoords(getCenterCoords(points));
    }

    const validateData = (data) => {
        let errors = {};
        data.forEach(item => {
            Object.keys(item).forEach(key => {
                const itemId = item.id;
                if ((item[key] === null || item[key] === undefined) && !['branche_id', 'tax_id'].includes(key)) errors[itemId] = {
                    ...errors[itemId],
                    [key]: {
                        message: 'Required'
                    }
                }
            })
        });

        if (!isEmpty(errors)) {
            const polygon = data.find(item => +item.id === +Object.keys(errors)?.[0]);
            setErrors(errors);
            focusZone(polygon?.id, polygon?.points)
            return true
        } else {
            setErrors(null);
            return false
        }
    }

    const submitForm = async () => {
        const isError = validateData(data);
        if (!isError) {
            onLoading();
            const requestBody = deliveryZonesRqBody(data);
            try {
                const { data: { data: zones } } = await setZonesApi({
                    datas: requestBody
                });
                handleSetData(branchId ? zones.filter(zone => zone.branche_id === branchId) : zones)
                toastAlert({ type: 'success' });
            } catch (error) {
                toastAlert({ type: 'error' });
            } finally {
                onFinally();
            }
        }
    };

    useEffect(() => {
        if (zones) {
           handleSetData(zones)
        }
    }, [zones])

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'vat',
            params: {
                page_size: 'all'
            }
        }))
    }, [dispatch])

    useEffect(() => {
        if (shopLocation) {
            setCenterCoords(shopLocation);
        } else {
            setCenterCoords({
                lat: 62.058473593872534, 
                lng: 9.501845677652613
            })
        }
    }, [shopLocation])

  return (
    <Card>

        <CardHeader>
            <CustomCardTitle>
                Delivery Zones
            </CustomCardTitle>
        </CardHeader>

       <CardBody>

            <Row className="gy-3">

                <Col lg={6} >
                    <Select
                        options={data?.map((item) => ({
                            label: item.name || t(`Unnamed`) + ` ${data.filter(it => !it.name).findIndex(it => it.id === item.id) + 1}`,
                            value: {
                                ...item
                            },
                        }))}
                        value={(() => {
                            const selectedItem = data?.find(item => item.id === selectedZone);
                            if (selectedItem) return {
                                label: selectedItem.name || t(`Unnamed ${data.filter(it => !it.name).findIndex(it => it.id === selectedItem.id) + 1}`),
                                value: {
                                    ...selectedItem
                                },
                            }
                            return
                        })()}
                        components={animatedComponents}
                        onChange={(val) => focusZone(val.value.id, val.value.points)}
                    />
                </Col>

                <Col lg={12}>
                    {mapsApiKey &&
                        <MapContainer 
                            data={data}
                            setData={setData}
                            selectedZone={selectedZone}
                            setSelectedZone={setSelectedZone}
                            centerCoords={centerCoords}
                            shopLocation={shopLocation}
                            errors={errors}
                            apiKey={mapsApiKey}
                            branchId={branchId}
                            branches={branches.filter(branch => branch.contact?.lat_long).map(branch => {
                                const coords = branch.contact.lat_long.split(',');
                                return {
                                    id: branch.id,
                                    name: branch.name,
                                    coords: {
                                        lat: +coords[0],
                                        lng: +coords[1]
                                    }
                                }
                            })}
                        />
                    }
                </Col>

                <Col md={6}>
                    <CustomLabel>Name</CustomLabel>
                    <CustomInput 
                        type="text" 
                        name="name"
                        value={data?.find(item => item.id === selectedZone)?.name ?? ''}
                        onChange={handleChange}
                    />
                    {errorMessage(errors?.[Object.keys(errors)?.[0]], 'name')}
                </Col>

                <Col md={6}>
                    <CustomLabel>Price</CustomLabel>
                    <CustomInput 
                        type="number" 
                        name="price"
                        value={data?.find(item => item.id === selectedZone)?.price ?? ''}
                        onChange={handleChange}
                    />
                    {errorMessage(errors?.[Object.keys(errors)?.[0]], 'price')}
                </Col>

                <Col md={6}>
                    <CustomLabel>Tax</CustomLabel>
                    <select 
                        name="tax_id"
                        className="form-select"
                        onChange={handleChange}
                        value={data?.find(item => item.id === selectedZone)?.tax_id ?? ''}
                    >
                        <CustomOption value="">Select</CustomOption>
                        {vat.map((vt) => (
                            <option key={vt.id} value={vt.id}>
                                {vt.name}
                            </option>
                        ))}
                    </select>
                    {errorMessage(errors?.[Object.keys(errors)?.[0]], 'tax_id')}
                </Col>

                {!branchId && 
                    <Col md={6}>
                        <CustomLabel>Branch</CustomLabel>
                        <select 
                            name="branche_id"
                            className="form-select" 
                            onChange={handleChange}
                            value={data?.find(item => item.id === selectedZone)?.branche_id ?? ''}
                        >
                            <CustomOption value="">Select</CustomOption>
                            {branches.map((branch) => (
                                <option key={branch.id} value={branch.id}>
                                    {branch.name}
                                </option>
                            ))}
                        </select>
                        {errorMessage(errors?.[Object.keys(errors)?.[0]], 'branche_id')}
                    </Col>
                }

                <Col md={6}>
                    <CustomLabel>Min Order</CustomLabel>
                    <CustomInput 
                        type="number" 
                        name="min_order"
                        onChange={handleChange}
                        value={data?.find(item => item.id === selectedZone)?.min_order ?? ''}
                    />
                    {errorMessage(errors?.[Object.keys(errors)?.[0]], 'min_order')}
                </Col>

                <Col lg={12}>
                    <CustomButton
                        isLoading={isSubmitting}
                        onClick={submitForm}
                        color="success"
                    >
                        Save
                    </CustomButton>
                </Col>
            </Row>

       </CardBody>
    </Card>
  )
}

export default DeliveryZones