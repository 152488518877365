import React from 'react'
import CustomLabel from '../../../Custom/CustomLabel'

const Cash = ({data, setData}) => {

  const handleCheckChange = (e) => {
    const field = e.target.name;
    const value = e.target.checked;
    setData(prev => prev.map(item => {
      if (item.id === data.id) return {
        ...item,
        [field]: value
      }
      return item
    }))
  }

  return (
    <React.Fragment>

        <div className="hstack gap-3">

            <div className="form-check form-switch">
                <input name="status" checked={data.status} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-cash" />
                <CustomLabel className="form-check-label" htmlFor="active-cash">Active</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="guest" checked={data.guest} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="cash-guest" />
                <CustomLabel className="form-check-label" htmlFor="cash-guest">Guest</CustomLabel>
            </div>

        </div>

    </React.Fragment>
  )
}

export default Cash