import { objectFilter } from "../../objectFilter";

const deliveryIds = {
    takeaway: 1,
    delivery: 2,
    restaurant: 3
}

const paymentIds = {
    cash: 1,
    online: 2,
}

export const reportRqBody = (data, period) => {

    const delivery_type_id = Object.entries(data.delivery_type).filter(([key, val]) => val).map(([key, val]) => deliveryIds[key.toLowerCase()]);
    const payment_method_id = Object.entries(data.payment).filter(([key, val]) => val).map(([key, val]) => paymentIds[key.toLowerCase()]);
    const platform = Object.entries(data.platform).filter(([key, val]) => val).map(([key, val]) => key);

    return objectFilter({
        report_type: data.report_type,
        delivery_type_id,
        payment_method_id,
        platform,
        ...data[period]
    })
}