import axiosInstance from "..";

export const reportsApi = {

      reports: {
          getData: (data) => {
              return axiosInstance.post("/report_settings/send_report", data);
          },
          display: (data) => {
              return axiosInstance.post("/report_settings/display_report", data);
          },
          print: (data) => {
              return axiosInstance.post("/report_settings/print_report", data);
          }
      },

      settings: {
        get: () => {
            return axiosInstance.get("/report_settings");
          },

        update: (data) => {
            return axiosInstance.post(`/report_settings`, data);
        },
        
    }, 
}