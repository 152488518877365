import React from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../Custom/CustomInput'
import CustomLabel from '../../../Custom/CustomLabel'

const Paypal = ({data, setData}) => {

  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setData(prev => prev.map(item => {
      if (item.id === data.id) return {
        ...item,
        [field]: value
      }
      return item
    }))
  }

  const handleCheckChange = (e) => {
    const field = e.target.name;
    const value = e.target.checked;
    setData(prev => prev.map(item => {
      if (item.id === data.id) return {
        ...item,
        [field]: value
      }
      return item
    }))
  }

  return (
    <React.Fragment>

        <div className="hstack gap-3">

            <div className="form-check form-switch">
                <input name="status" checked={data.status} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-paypal" />
                <CustomLabel className="form-check-label" htmlFor="active-paypal">Active</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="status_test_mode" checked={data.status_test_mode} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-paypal-test-mode" />
                <CustomLabel className="form-check-label" htmlFor="active-paypal-test-mode">Test Mode</CustomLabel>
            </div>

        
            <div className="form-check form-switch">
                <input name="guest" checked={data.guest} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="paypal-guest" />
                <CustomLabel className="form-check-label" htmlFor="paypal-guest">Guest</CustomLabel>
            </div>

        </div>

        <Row className='mt-1 gy-3'>

            <Col lg={6}>
                <CustomLabel>Client id</CustomLabel>
                <CustomInput 
                    type="text"
                    name="client_id"
                    value={data.client_id}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Client Secret</CustomLabel>
                <CustomInput 
                    type="text"
                    name="client_secret"
                    value={data.client_secret}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Client test id</CustomLabel>
                <CustomInput 
                    type="text"
                    name="client_id_test"
                    value={data.client_id_test}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Client test secret</CustomLabel>
                <CustomInput 
                    type="text"
                    name="client_secret_test"
                    value={data.client_secret_test}
                    onChange={handleChange}
                />
            </Col>
        </Row>

    </React.Fragment>

  )
}

export default Paypal