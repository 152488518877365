import React from 'react'
import BreadCrumb from '../../Components/Common/BreadCrumb';
import PageContainer from '../../Components/Layout/PageContainer'
import ReportsForm from '../../Components/Reports/Reports/Form'

const Reports = () => {

  return (
    <PageContainer>
        <BreadCrumb 
            pageTitle="Reports"
            title="Reports"
        />
        <ReportsForm />
    </PageContainer>
  )
}

export default Reports