import React from 'react'
import Checkbox from 'react-three-state-checkbox'
import { Table } from 'reactstrap'

const BootstrapTable = ({ columns, data, clickableRows, onRowClick, extraRows, enableSelection, onSelectChange, onSelectAllChange }) => {

  return (

    <Table
        striped
        bordered
        responsive
        variant="dark"
        className='bootstrap-table'
    >
        <thead>
            <tr>
                {
                    enableSelection && 
                    <th
                        className='selection-column-head'
                    >
                        <div>
                            <Checkbox
                                className="form-check-input"
                                checked={data?.length && data?.every((item) => item.selected)}
                                indeterminate={data?.length && data?.some((item) => item.selected) && !data.every((item) => item.selected)}
                                onChange={onSelectAllChange}
                            />
                        </div>
                    </th>
                }
                {columns.map((column, index) => (
                    <th key={index} style={{...column.style}}>
                        <div>
                            {column.name}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>

        <tbody>
            {data.map((row, index) => (
                <tr 
                    key={index}
                    onClick={() => {
                        if (clickableRows) onRowClick(row)
                    }}
                    className={clickableRows ? 'clickable-row' : null}
                >
                    {
                        enableSelection && 
                            <td className='selection-column-cell'>
                                <div>
                                    <input
                                        type="checkbox"
                                        id={`item-${row.id}`}
                                        className="form-check-input fs-16"
                                        checked={row.selected || false}
                                        onChange={(e) => onSelectChange(row.id, 'selected', e.target.checked)}
                                    />
                                </div>
                            </td>
                    }
                    {columns.filter(column => column.name && column.selector).map((column, index) => (
                        <td key={index}>
                            <div>
                                {column.selector(row)}
                            </div>
                        </td>
                    ))}
                </tr>
            ))}
            {
                extraRows?.map((item, index) => (
                    <React.Fragment key={index}>
                        {item}
                    </React.Fragment>
                ))
            }
        </tbody>
    </Table>
  )
}

export default BootstrapTable