import axiosInstance from "..";

export const getAllUsersApi = ({ page, pageSize, name } = {
  page: 1, pageSize: 10
}) => {
  return axiosInstance.get("/users", {
    params: {
      page,
      page_size: pageSize,
      name,
    },
  });
};

export const getOneUserApi = (id) => {
  return axiosInstance.get(`/users/${id}`);
};

export const createUserApi = (data) => {
  return axiosInstance.post("/users", data);
};

export const updateUser = (id, data) => {
  return axiosInstance.post(`/users/${id}`, data);
};

export const deleteUsersApi = (ids) => {
  return axiosInstance.delete("/users", {
    data: {
      ids,
    },
  });
};
