import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthenticatedAdmin } from "../../apis/Admin/adminApis";
import { getAllLanguagesApi } from "../../apis/Settings/languageApi";
import { getFooterApi, getSetupApi } from "../../apis/Settings/webSettingsApi";
import { adminActions } from "../../redux/slices/adminSlice";
import { languageActions } from "../../redux/slices/languageSlice";
import { settingsActions } from "../../redux/slices/settingsSlice";
import { Spinner } from "reactstrap";
import i18n from "../../i18n";
import { getPaymentSettingsAction } from "../../redux/actions/settingsActions";
import { useLocation } from 'react-router-dom'
import { getPageTitle } from "../../helpers/getPageTtitle";

const AppWrapper = ({ children }) => {

  const { setup } = useSelector((state) => state.settings);

  const { activeLang } = useSelector((state) => state.language);

  const { token } = useSelector((state) => state.admin);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const location = useLocation();

  const getDefaultLang = async (languages) => {
    let language;
    const localLanguage = localStorage.getItem("I18N_LANGUAGE");
    if (languages?.length) {
      language = localLanguage || languages?.find(lang => lang.default_dash)?.code || 'en';
    } else {
      language = localLanguage || 'en'
    }
    localStorage.setItem("I18N_LANGUAGE", language);
    await i18n.changeLanguage(language);
    dispatch(languageActions.setActiveLang(language));
  };

  const fetchAdmin = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await getAuthenticatedAdmin();
      dispatch(adminActions.getAdminData(data));
    } catch (error) {
      if (error?.response?.status === 401) dispatch(adminActions.logout());
    } finally {
      setLoading(false);
    }
  };

  const { refetch: refetchFooter } = useQuery(["web-settings", "footer"], getFooterApi,
    {
      enabled: false,
      onSuccess(data) {
        const { footer, copyright } = data.data.data;
        dispatch(settingsActions.setFooter({ footer, copyright }));
      },
    }
  );

  const { refetch: refetchSetup, isLoading: setupLoading, isRefetching: setupRefetching } = useQuery(["web-settings", "setup"], getSetupApi,
    {
      enabled: false,
      onSuccess(data) {
        const { name, logo, fav_icon } = data.data.data;
        dispatch(
          settingsActions.setSetup({
            name,
            logo: logo?.link,
            fav_icon: fav_icon?.link,
          })
        );
      },
    }
  );

  const { refetch: refetchLanguages } = useQuery(["languages"], () => getAllLanguagesApi({ page_size: 'all' }),
    {
      enabled: false,
      onSuccess({data: { data }}) {
        dispatch(languageActions.getLanguages(data));
        getDefaultLang(data);
      },
      onError() {
        getDefaultLang();
      }
    }
  );

  useEffect(() => {
    if (token) {
      fetchAdmin();
    } else {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    refetchSetup();
    refetchLanguages();
    refetchFooter();
    dispatch(getPaymentSettingsAction());
  }, [])

  
  useEffect(() => {
    if (activeLang) {
        document.documentElement.lang = activeLang
      if (activeLang === 'ar') {
        document.documentElement.dir =  "rtl";
      } else {
        document.documentElement.dir =  "ltr";
      }
    }
  }, [activeLang])

  // Change website name and favicon based on setup

  useEffect(() => {
    
    if (!setupLoading && !setupRefetching) {
      const path = location.pathname;

      const pageTitle = getPageTitle(path);

      const websiteName = setup.name || 'Restaurant';

      if (pageTitle) {
        document.title = pageTitle;
      } else if (websiteName && document.title !== websiteName) {
        document.title = websiteName
      }

      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      if (setup.fav_icon) {
        link.href = setup.fav_icon;
      }
    }
  }, [setup, location.pathname, setupLoading, setupRefetching])

  return (
    <>
      {(loading && activeLang) ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center",
            paddingBlock: "1rem",
          }}
        >
          <Spinner />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default AppWrapper;
