import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card, Col, Modal, ModalHeader, Row } from "reactstrap";
import CreateButton from "../../../Components/Common/Buttons/CreateButton";
import DeleteButton from "../../../Components/Common/Buttons/DeleteButton";
import DataTableContainer from "../../../Components/Common/DataTableContainer";
import UserAvatar from "../../../Components/Common/UserAvatar";
import CustomInput from "../../../Components/Custom/CustomInput";
import CustomTableHeading from "../../../Components/Custom/CustomTableHeading";
import Restricted from "../../../Components/Guard/Restricted";
import PageContainer from "../../../Components/Layout/PageContainer";
import AdminFormModal from "../../../Components/Settings/Admins/AdminFormModal";
import { isAllowed } from "../../../helpers/isAllowed";
import useActions from "../../../hooks/useActions";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import useFilterData, { FILTER_TYPES } from "../../../hooks/useFilterData";
import useModal from "../../../hooks/useModal";
import { useAdmins, useBranches, useRoles } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const Admins = () => {
  const { t } = useTranslation();

  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onFinally: onDeletingFinally,
  } = useActions();

  const { getSelectedIds, selectItems, isDisable } = useSelectItems();

  const { user } = useSelector((state) => state.admin);

  const { admins, createNewAdmin, updateAdmin, deleteAdmins, isLoading } = useAdmins();

  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const [query, setQuery] = useState("");

  const [userName, setUserName] = useState("");

  const { branches } = useBranches();

  const { roles } = useRoles();

  const data = useFilterData(admins, {
    name: {
      key: "name",
      type: FILTER_TYPES.MATCH,
      value: query,
    },
    username: {
      key: "username",
      type: FILTER_TYPES.MATCH,
      value: userName,
    },
  });

  const {
    isModalOpen: isOpen,
    onAddClick,
    onUpdateClick,
    initValues,
    type,
    toggleModal,
  } = useCreateUpdateModal();

  const onDeleteSubmit = () => {
    onDeleting();
    const ids = getSelectedIds();
    deleteAdmins(ids, {
      onFinally: onDeletingFinally,
      onSuccess() {
        selectItems([]);
        closeModal();
      },
    });
  };

  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id || "-",
    },
    {
      name: (
        <CustomInput
          placeholder="Name"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          data-filtered={!!query}
        />
      ),
      selector: (row) => (
        <div data-tag="allowRowEvents">
          <UserAvatar image={row.image} />
          {row.name}
        </div>
      ),
    },

    {
      name: (
        <CustomInput
          placeholder="Username"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          data-filtered={!!userName}
        />
      ),
      selector: (row) => row.username,
    },

    {
      name: <CustomTableHeading>Email</CustomTableHeading>,
      selector: (row) => row.email,
    },

    {
      name: <CustomTableHeading>Role</CustomTableHeading>,
      selector: (row) => row.role.join(","),
    },
  ];

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="Settings" title="Admins"/>
      <Row className="gy-4">
        <Col xs={12}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2">
              <CreateButton onClick={onAddClick} />

              <Restricted allowed={["super-admin", "developer"]}>
                <DeleteButton disabled={isDisable} onClick={openModal} />
              </Restricted>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <Card>
            <DataTableContainer
              columns={columns}
              data={data}
              handleClick={(row) =>
                onUpdateClick({
                  ...row,
                  role: row.role?.[0] || "",
                })
              }
              modal={isModalOpen}
              setModal={setIsModalOpen}
              selectableRows={isAllowed(user)}
              isDeleting={isDeleting}
              selectItems={selectItems}
              handleDelete={onDeleteSubmit}
            />
          </Card>
        </Col>
      </Row>
      <Modal centered isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t("Admin form")}</ModalHeader>
        <AdminFormModal
          type={type}
          initialValues={initValues}
          roles={roles}
          createNewAdmin={createNewAdmin}
          toggleModal={toggleModal}
          updateAdmin={updateAdmin}
          branches={branches}
        />
      </Modal>
    </PageContainer>
  );
};

export default Admins;
