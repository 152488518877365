export const foodFormTabs = [    
    {
        id: 1, 
        tabs: [
            { label: 'Components', value: 'components' }, 
            { label: 'Entrees', value: 'entrees' },
            { label: 'Options & Allergy', value: 'optionsAllergy' }, 
            { label: 'Price', value: 'price' }, 
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
    {
        id: 2,
        tabs: [
            { label: 'Components', value: 'components' },
            { label: 'Entrees', value: 'entrees' },
            { label: 'Options & Allergy', value: 'optionsAllergy' },
            { label: 'Price', value: 'price' },
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
    {
        id: 3,
        tabs: [
            { label: 'Components', value: 'components' },
            { label: 'Entrees', value: 'entrees' },
            { label: 'Options & Allergy', value: 'optionsAllergy' },
            { label: 'Price', value: 'price' },
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
    {
        id: 4,
        tabs: [
            { label: 'Pizza List', value: 'pizzaList' },
            { label: 'Description', value: 'description' },
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
    {
        id: 5,
        tabs: [
            { label: 'Components', value: 'components' },
            { label: 'Entrees', value: 'entrees' },
            { label: 'Options & Allergy', value: 'optionsAllergy' },
            { label: 'Price', value: 'price' },
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
    {
        id: 6,
        tabs: [
            { label: 'Components', value: 'description' },
            { label: 'Entrees', value: 'entrees' },
            { label: 'Options & Allergy', value: 'optionsAllergy' },
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
    {
        id: 7,
        tabs: [
            { label: 'Products', value: 'products' },
            { label: 'Description', value: 'description' },
            { label: 'Entrees', value: 'entrees' },
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
    {
        id: 8,
        tabs: [
            { label: 'Sizes', value: 'sizes' },
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
    {
        id: 9,
        tabs: [
            { label: 'Components', value: 'components' },
            { label: 'Entrees', value: 'entrees' },
            { label: 'Options & Allergy', value: 'optionsAllergy' },
            { label: 'Price', value: 'price' },
            { label: 'Date & Time', value: 'dateTime' }, 
        ]
    },
]
