import React from "react";
import { Redirect } from "react-router-dom";
import { getPathname } from "../Layouts/LayoutMenuData";

// ! Setup
import Setup from "../pages/Setup";

// ! Home
import Home from "../pages/Home";

// ! Orders
// Order List
import OrderList from "../pages/Orders/List";
import OrderDetails from "../pages/Orders/List/Details";
import TrackOrder from "../pages/Orders/List/TrackOrder";
import TrackDeliveryAgents from "../pages/Orders/TrackDeliveryAgents";
// Online Settings
import OnlineSettings from "../pages/Orders/OnlineSettings";
// Delivery Settings
import DeliverySettings from "../pages/Orders/DeliverySettings";
// SMS Settings
import SmsSettings from "../pages/Orders/SmsSettings";
// Rooms
import Rooms from "../pages/Orders/Rooms";
import CreateRoom from "../pages/Orders/Rooms/Create";
import UpdateRoom from "../pages/Orders/Rooms/Update";
// Tables
import Tables from "../pages/Orders/Tables";
import CreateTable from "../pages/Orders/Tables/Create";
import UpdateTable from "../pages/Orders/Tables/Update";
// Table Services
import TableServices from "../pages/Orders/TableServices";
import CreateTableService from "../pages/Orders/TableServices/Create";
import UpdateTableService from "../pages/Orders/TableServices/Update";
// Coupons
import Coupons from "../pages/Orders/Coupons";
import CreateCoupon from "../pages/Orders/Coupons/Create";
import UpdateCoupon from "../pages/Orders/Coupons/Update";
// Shelves
import Shelves from "../pages/Orders/Shelves";
import CreateShelf from "../pages/Orders/Shelves/Create";
import UpdateShelf from "../pages/Orders/Shelves/Update";
// BlackAndWhite
import BlackAndWhite from "../pages/Orders/BlackAndWhite";
import CreateBlackAndWhite from "../pages/Orders/BlackAndWhite/Create";
import UpdateBlackAndWhite from "../pages/Orders/BlackAndWhite/Update";

// ! Food
// Menu
import FoodMenu from "../pages/Food/Menu";
import CreateFood from "../pages/Food/Menu/Create";
import UpdateFood from "../pages/Food/Menu/Update";
// Categories
import Categories from "../pages/Food/Categories";
import CreateCategory from "../pages/Food/Categories/Create";
import UpdateCategory from "../pages/Food/Categories/Update";
import SortCategories from "../pages/Food/Categories/Sort";
// Components
import Components from "../pages/Food/Components";
import CreateComponent from "../pages/Food/Components/Create";
import UpdateComponent from "../pages/Food/Components/Update";
import SortComponents from "../pages/Food/Components/Sort";
import SortComponentItems from "../pages/Food/Components/SortItems";
// Allergy
import Allergy from "../pages/Food/Allergy";
import CreateAllergy from "../pages/Food/Allergy/Create";
import UpdateAllergy from "../pages/Food/Allergy/Update";
// options
import Options from "../pages/Food/Options";
import CreateOption from "../pages/Food/Options/Create";
import UpdateOption from "../pages/Food/Options/Update";
// Sizes
import CreateSize from "../pages/Food/Sizes/Create";
import Sizes from "../pages/Food/Sizes";
import UpdateSize from "../pages/Food/Sizes/Update";
import SortSizes from "../pages/Food/Sizes/Sort";
// Dough and Bread
import DoughAndBread from "../pages/Food/DoughAndBread";
import CreateDoughAndBread from "../pages/Food/DoughAndBread/Create";
import UpdateDoughAndBread from "../pages/Food/DoughAndBread/Update";
import SortDoughAndBread from "../pages/Food/DoughAndBread/Sort";
// Doneness
import Doneness from "../pages/Food/Doneness";
import CreateDoneness from "../pages/Food/Doneness/Create";
import UpdateDoneness from "../pages/Food/Doneness/Update";
// Spice Levels
import SpiceLevels from "../pages/Food/SpiceLevels";
import CreateSpiceLevel from "../pages/Food/SpiceLevels/Create";
import UpdateSpiceLevel from "../pages/Food/SpiceLevels/Update";
// Vat
import Vat from "../pages/Food/Vat";
// Printers
import Printers from "../pages/Food/Printers";
import CreatePrinter from "../pages/Food/Printers/Create";
import UpdatePrinter from "../pages/Food/Printers/Update";

// ! Users
// Users
import Users from "../pages/Users/Users";
import CreateUser from "../pages/Users/Users/Create";
import UpdateUser from "../pages/Users/Users/Update";
// Groups
import Groups from "../pages/Users/Groups";
import CreateGroup from "../pages/Users/Groups/Create";
import UpdateGroup from "../pages/Users/Groups/Update";

// ! Settings
// Admins
import Admins from "../pages/Settings/Admins";
// Web Settings
import WebSettings from "../pages/Settings/WebSettings";
// Payment
import Payment from "../pages/Settings/Payment";
// Social Logins
import SocialLogins from "../pages/Settings/SocialLogins";
// Branches
import Branches from "../pages/Settings/Branches";
import CreateBranch from "../pages/Settings/Branches/Create";
import UpdateBranch from "../pages/Settings/Branches/Update";
// Home Sliders
import HomeSliders from "../pages/Settings/HomeSliders";
import CreateHomeSlider from "../pages/Settings/HomeSliders/Create";
import UpdateHomeSlider from "../pages/Settings/HomeSliders/Update";
import SortHomeSlider from "../pages/Settings/HomeSliders/Sort";
// Pages Menu
import PagesMenu from "../pages/Settings/PagesMenu";
import CreatePagesMenu from "../pages/Settings/PagesMenu/Create";
import UpdatePagesMenu from "../pages/Settings/PagesMenu/Update";
import SortPagesMenu from "../pages/Settings/PagesMenu/Sort";
// Gallery
import Gallery from "../pages/Settings/Gallery";
import CreateGallery from "../pages/Settings/Gallery/Create";
import UpdateGallery from "../pages/Settings/Gallery/Update";
import SortGallery from "../pages/Settings/Gallery/Sort";
// Banners
import Banners from "../pages/Settings/Banners/index.jsx";
import CreateBanner from "../pages/Settings/Banners/Create.jsx";
import UpdateBanner from "../pages/Settings/Banners/Update.jsx";
// Language
import SelectLanguage from "../pages/Settings/Language.jsx/Select";
import Language from "../pages/Settings/Language.jsx/index.jsx";
import CreateLanguage from "../pages/Settings/Language.jsx/Create";
import UpdateLanguage from "../pages/Settings/Language.jsx/Update";
import LanguageKeys from "../pages/Settings/Language.jsx/Keys";
// Menu
import Menu from "../pages/Settings/Menu";
// Menu Builder
import MenuBuilder from "../pages/Settings/MenuBuilder";

// ! Reports
import Reports from "../pages/Reports/Index";
import ReportsSettings from "../pages/Reports/Settings";

// ! Auth
import Login from "../pages/Auth/Login";
import Keys from "../pages/Settings/Keys";
import Offers from "../pages/Food/Offers/index.jsx";
import CreateOffer from "../pages/Food/Offers/Create.jsx";
import UpdateOffer from "../pages/Food/Offers/Update.jsx";

const setupRoutes = [

  // ! Setup

  {
    path: "/setup",
    exact: true,
    component: () => <Setup />,
  },
]

const authProtectedRoutes = [


  // ! Home

  {
    path: getPathname(),
    exact: true,
    component: Home,
  },

  // ! Orders

  // Order List

  {
    path: getPathname("orders"),
    exact: true,
    component: () => <OrderList />,
  },

  {
    path: getPathname("orders/update/:id"),
    exact: true,
    component: () => <OrderDetails />,
  },

  {
    path: getPathname("orders/track/:id"),
    exact: true,
    component: () => <TrackOrder />,
  },

  // Online Settings

  {
    path: getPathname("orders/settings"),
    exact: true,
    component: () => <OnlineSettings />,
  },

  // Delivery Settings

  {
    path: getPathname("orders/delivery-settings"),
    exact: true,
    component: () => <DeliverySettings />,
  },

  // SMS Settings

  {
    path: getPathname("orders/sms-settings"),
    exact: true,
    component: () => <SmsSettings />,
  },

  // Track Delivery Agents

  {
    path: getPathname("orders/track-agents"),
    exact: true,
    component: () => <TrackDeliveryAgents />,
  },

  // Rooms

  {
    path: getPathname("orders/rooms"),
    exact: true,
    component: () => <Rooms />,
  },

  {
    path: getPathname("orders/rooms/create"),
    exact: true,
    component: () => <CreateRoom />,
  },

  {
    path: getPathname("orders/rooms/update/:id"),
    exact: true,
    component: () => <UpdateRoom />,
  },

  // Tables

  {
    path: getPathname("orders/tables"),
    exact: true,
    component: () => <Tables />,
  },

  {
    path: getPathname("orders/tables/create"),
    exact: true,
    component: () => <CreateTable />,
  },

  {
    path: getPathname("orders/tables/update/:id"),
    exact: true,
    component: () => <UpdateTable />,
  },

  // Table Services

  {
    path: getPathname("orders/table-services"),
    exact: true,
    component: () => <TableServices />,
  },

  {
    path: getPathname("orders/table-services/create"),
    exact: true,
    component: () => <CreateTableService />,
  },

  {
    path: getPathname("orders/table-services/update/:id"),
    exact: true,
    component: () => <UpdateTableService />,
  },

  // Coupons

  {
    path: getPathname("orders/coupons"),
    exact: true,
    component: () => <Coupons />,
  },

  {
    path: getPathname("orders/coupons/create"),
    exact: true,
    component: () => <CreateCoupon />,
  },

  {
    path: getPathname("orders/coupons/update/:id"),
    exact: true,
    component: () => <UpdateCoupon />,
  },

  // Shelves

  {
    path: getPathname("orders/shelves"),
    exact: true,
    component: () => <Shelves />,
  },

  {
    path: getPathname("orders/shelves/create"),
    exact: true,
    component: () => <CreateShelf />,
  },

  {
    path: getPathname("orders/shelves/update/:id"),
    exact: true,
    component: () => <UpdateShelf />,
  },

  // Black & White

  {
    path: getPathname("orders/black-white"),
    exact: true,
    component: () => <BlackAndWhite />,
  },

  {
    path: getPathname("orders/black-white/create"),
    exact: true,
    component: () => <CreateBlackAndWhite />,
  },

  {
    path: getPathname("orders/black-white/update/:id"),
    exact: true,
    component: () => <UpdateBlackAndWhite />,
  },

  // ! Food

  // Menu

  {
    path: getPathname("food/menu"),
    exact: true,
    component: () => <FoodMenu />,
  },

  {
    path: getPathname("food/menu/create"),
    exact: true,
    component: () => <CreateFood />,
  },

  {
    path: getPathname("food/menu/update/:id"),
    exact: true,
    component: () => <UpdateFood />,
  },

  // Categories

  {
    path: getPathname("food/categories"),
    exact: true,
    component: () => <Categories />,
  },

  {
    path: getPathname("food/categories/create"),
    exact: true,
    component: () => <CreateCategory />,
  },

  {
    path: getPathname("food/categories/update/:id"),
    exact: true,
    component: () => <UpdateCategory />,
  },

  {
    path: getPathname("food/categories/sort"),
    exact: true,
    component: () => <SortCategories />,
  },

  // Components

  {
    path: getPathname("food/components"),
    exact: true,
    component: () => <Components />,
  },

  {
    path: getPathname("food/components/create"),
    exact: true,
    component: () => <CreateComponent />,
  },

  {
    path: getPathname("food/components/update/:id"),
    exact: true,
    component: () => <UpdateComponent />,
  },

  {
    path: getPathname("food/components/sort"),
    exact: true,
    component: () => <SortComponents />,
  },

  {
    path: getPathname(`food/components/sort-items/:id`),
    exact: true,
    component: () => <SortComponentItems />,
  },

  // Offers

  {
    path: getPathname("food/offers"),
    exact: true,
    component: () => <Offers />,
  },

  {
    path: getPathname("food/offers/create"),
    exact: true,
    component: () => <CreateOffer />,
  },

  {
    path: getPathname("food/offers/update/:id"),
    exact: true,
    component: () => <UpdateOffer />,
  },

  // Allergy

  {
    path: getPathname("food/allergy"),
    exact: true,
    component: () => <Allergy />,
  },

  {
    path: getPathname("food/allergy/create"),
    exact: true,
    component: () => <CreateAllergy />,
  },

  {
    path: getPathname("food/allergy/update/:id"),
    exact: true,
    component: () => <UpdateAllergy />,
  },

  // Options

  {
    path: getPathname("food/options"),
    exact: true,
    component: () => <Options />,
  },

  {
    path: getPathname("food/options/create"),
    exact: true,
    component: () => <CreateOption />,
  },

  {
    path: getPathname("food/options/update/:id"),
    exact: true,
    component: () => <UpdateOption />,
  },

  // Sizes

  {
    path: getPathname("food/sizes"),
    exact: true,
    component: () => <Sizes />,
  },

  {
    path: getPathname("food/sizes/create"),
    exact: true,
    component: () => <CreateSize />,
  },

  {
    path: getPathname("food/sizes/update/:id"),
    exact: true,
    component: () => <UpdateSize />,
  },

  {
    path: getPathname("food/sizes/sort"),
    exact: true,
    component: () => <SortSizes />,
  },

  // Dough and Bread

  {
    path: getPathname("food/dough-bread"),
    exact: true,
    component: () => <DoughAndBread />,
  },

  {
    path: getPathname("food/dough-bread/create"),
    exact: true,
    component: () => <CreateDoughAndBread />,
  },

  {
    path: getPathname("food/dough-bread/update/:id"),
    exact: true,
    component: () => <UpdateDoughAndBread />,
  },

  {
    path: getPathname("food/dough-bread/sort"),
    exact: true,
    component: () => <SortDoughAndBread />,
  },

  // Donenesss

  {
    path: getPathname("food/doneness"),
    exact: true,
    component: () => <Doneness />,
  },

  {
    path: getPathname("food/doneness/create"),
    exact: true,
    component: () => <CreateDoneness />,
  },

  {
    path: getPathname("food/doneness/update/:id"),
    exact: true,
    component: () => <UpdateDoneness />,
  },

  // Spice Levels

  {
    path: getPathname("food/spice-levels"),
    exact: true,
    component: () => <SpiceLevels />,
  },

  {
    path: getPathname("food/spice-levels/create"),
    exact: true,
    component: () => <CreateSpiceLevel />,
  },

  {
    path: getPathname("food/spice-levels/update/:id"),
    exact: true,
    component: () => <UpdateSpiceLevel />,
  },

  // Vat

  {
    path: getPathname("food/vat"),
    exact: true,
    component: () => <Vat />,
  },

  // Printers

  {
    path: getPathname("food/printers"),
    exact: true,
    component: () => <Printers />,
  },

  {
    path: getPathname("food/printers/create"),
    exact: true,
    component: () => <CreatePrinter />,
  },

  {
    path: getPathname("food/printers/update/:id"),
    exact: true,
    component: () => <UpdatePrinter />,
  },

  // ! Users

  // Users

  {
    path: getPathname("users"),
    exact: true,
    component: () => <Users />,
  },

  {
    path: getPathname("users/create"),
    exact: true,
    component: () => <CreateUser />,
  },

  {
    path: getPathname("users/update/:id"),
    exact: true,
    component: () => <UpdateUser />,
  },

  // Groups

  {
    path: getPathname("users/groups"),
    exact: true,
    component: () => <Groups />,
  },

  {
    path: getPathname("users/groups/create"),
    exact: true,
    component: () => <CreateGroup />,
  },

  {
    path: getPathname("users/groups/update/:id"),
    exact: true,
    component: () => <UpdateGroup />,
  },

  // ! Settings

  // Admins

  {
    path: getPathname("admins"),
    exact: true,
    component: () => <Admins />,
  },

  // Web Settings

  {
    path: getPathname("settings"),
    exact: true,
    component: () => <WebSettings />,
  },

  // Payment

  {
    path: getPathname("settings/payment"),
    exact: true,
    component: () => <Payment />,
  },

  // Social Logins

  {
    path: getPathname("settings/social-logins"),
    exact: true,
    component: () => <SocialLogins />,
  },

  {
    path: getPathname("settings/keys"),
    exact: true,
    component: () => <Keys />,
  },

  // Branches

  {
    path: getPathname("settings/branches"),
    exact: true,
    component: () => <Branches />,
  },

  {
    path: getPathname("settings/branches/create"),
    exact: true,
    component: () => <CreateBranch />,
  },

  {
    path: getPathname("settings/branches/update/:id"),
    exact: true,
    component: () => <UpdateBranch />,
  },

  // Home Sliders

  {
    path: getPathname("settings/home-sliders"),
    exact: true,
    component: () => <HomeSliders />,
  },

  {
    path: getPathname("settings/home-sliders/create"),
    exact: true,
    component: () => <CreateHomeSlider />,
  },

  {
    path: getPathname("settings/home-sliders/update/:id"),
    exact: true,
    component: () => <UpdateHomeSlider />,
  },

  {
    path: getPathname("settings/home-sliders/sort"),
    exact: true,
    component: () => <SortHomeSlider />,
  },

  // Pages Menu

  {
    path: getPathname("settings/pages-menu"),
    exact: true,
    component: () => <PagesMenu />,
  },

  {
    path: getPathname("settings/pages-menu/create"),
    exact: true,
    component: () => <CreatePagesMenu />,
  },

  {
    path: getPathname("settings/pages-menu/sort"),
    exact: true,
    component: () => <SortPagesMenu />,
  },

  {
    path: getPathname("settings/pages-menu/update/:id"),
    exact: true,
    component: () => <UpdatePagesMenu />,
  },

  // Gallery

  {
    path: getPathname("settings/gallery"),
    exact: true,
    component: () => <Gallery />,
  },

  {
    path: getPathname("settings/gallery/create"),
    exact: true,
    component: () => <CreateGallery />,
  },

  {
    path: getPathname("settings/gallery/sort"),
    exact: true,
    component: () => <SortGallery />,
  },

  {
    path: getPathname("settings/gallery/update/:id"),
    exact: true,
    component: () => <UpdateGallery />,
  },

  // Banners

  {
    path: getPathname("settings/banners"),
    exact: true,
    component: () => <Banners />,
  },

  {
    path: getPathname("settings/banners/create"),
    exact: true,
    component: () => <CreateBanner />,
  },

  {
    path: getPathname("settings/banners/update/:id"),
    exact: true,
    component: () => <UpdateBanner />,
  },

  // Language

  {
    path: getPathname("settings/language"),
    exact: true,
    component: () => <Language />,
  },

  {
    path: getPathname("settings/language/create"),
    exact: true,
    component: () => <CreateLanguage />,
  },

  {
    path: getPathname("settings/language/update/:code"),
    exact: true,
    component: () => <UpdateLanguage />,
  },

  {
    path: getPathname("settings/language/keys/:code"),
    exact: true,
    component: () => <SelectLanguage />,
  },

  {
    path: getPathname("settings/language/keys/:code/:whatFor"),
    exact: true,
    component: () => <LanguageKeys />,
  },

  // Menu

  {
    path: getPathname("settings/menu"),
    exact: true,
    component: () => <Menu />,
  },

  
  // Menu Builder

  {
    path: getPathname("settings/menu-builder"),
    exact: true,
    component: () => <MenuBuilder />,
  },


  // ! Reports

  // Reports

  {
    path: getPathname("reports"),
    exact: true,
    component: () => <Reports />,
  },

  // Settings

  {
    path: getPathname("reports/settings"),
    exact: true,
    component: () => <ReportsSettings />,
  },

  // * Guard


  {
    path: "*",
    exact: true,
    component: () => <Redirect to="/panel" />,
  },
];

const publicRoutes = [{ path: "/login", component: Login }];

export { authProtectedRoutes, publicRoutes, setupRoutes };
