import { useEffect, useState } from 'react'
import React from 'react'
import { Col, Row } from 'reactstrap'
import SelectFile from '../../../Common/SelectFile'
import CustomInput from '../../../Custom/CustomInput'
import CustomLabel from '../../../Custom/CustomLabel'
import { toBase64 } from '../../../../helpers/toBase64'

const SumUp = ({data, setData}) => {

    const [image, setImage] = useState({
        url: data?.logo,
        file: null
    })

    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setData(prev => prev.map(item => {
          if (item.id === data.id) return {
            ...item,
            [field]: value
          }
          return item
        }))
    }
    
    const handleCheckChange = (e) => {
        const field = e.target.name;
        const value = e.target.checked;
        setData(prev => prev.map(item => {
            if (item.id === data.id) return {
            ...item,
            [field]: value
            }
            return item
        }))
    }

    const handleFileChange = async () => {
        const url = await toBase64(image.file);
        setData(prev => prev.map(item => {
            if (item.id === data.id) return {
            ...item,
            logo: url
            }
            return item
        }))
    }   

    useEffect(() => {
        if (image.file) {
            handleFileChange();
        }
    }, [image])

  return (
    <React.Fragment>

        <div className="hstack gap-3">
            
            <div className="form-check form-switch">
                <input name="status" checked={data.status} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-sumup" />
                <CustomLabel className="form-check-label" htmlFor="active-sumup">Active</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="guest" checked={data.guest} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="sumup-guest" />
                <CustomLabel className="form-check-label" htmlFor="sumup-guest">Guest</CustomLabel>
            </div>

        </div>

        <Row className='mt-1 gy-3'>

            <Col lg={6}>
                <CustomLabel>Client ID</CustomLabel>
                <CustomInput 
                    type="text"
                    name="client_id"
                    value={data.client_id}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Client Secret</CustomLabel>
                <CustomInput 
                    type="text"
                    name="client_secret"
                    value={data.client_secret}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Merchant Code</CustomLabel>
                <CustomInput 
                    type="text"
                    name="merchant_code"
                    value={data.merchant_code}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Pay To Email</CustomLabel>
                <CustomInput 
                    type="text"
                    name="pay_to_email"
                    value={data.pay_to_email}
                    onChange={handleChange}
                />
            </Col>

        </Row>

    </React.Fragment>

  )
}

export default SumUp